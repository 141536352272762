import React, { useEffect } from "react";
import "./about.css";
import Navbar from "../Navbar/Navbar";
import Footer from "../Footer/Footer";
const About = () => {
  useEffect(() => {
    document.title = "ABOUT US || SN MARINE ENTERPRISE"; // Set default title
  }, []);
  return (
    <>
      <Navbar />
      <div
        className="container-fluid page-header py-5"
        style={{ marginBottom: "6rem", marginTop: -90 }}
      >
        <div className="container py-5">
          <h1 className="display-3 text-white mb-3 animated slideInDown">
            About Us
          </h1>
          <nav aria-label="breadcrumb animated slideInDown">
            <ol className="breadcrumb">
              <li className="breadcrumb-item">
                <a className="text-white" href="#">
                  Home
                </a>
              </li>
              {/* <li className="breadcrumb-item">
                <a className="text-white" href="#">
                  Pages
                </a>
              </li> */}
              <li
                className="breadcrumb-item text-white active"
                aria-current="page"
              >
                About Us
              </li>
            </ol>
          </nav>
        </div>
      </div>

      <div className="container-fluid overflow-hidden py-5 px-lg-0">
        <div className="container about py-5 px-lg-0">
          <div className="row g-5 mx-lg-0">
            <div
              className="col-lg-6 ps-lg-0 wow fadeInLeft"
              data-wow-delay="0.1s"
              style={{ minHeight: 400 }}
            >
              <div className="position-relative h-100">
                <img
                  className="img-fluid w-100 h-100"
                  src="https://assets.angloeastern.com/assets/2023/08/crew-management_streamlined-processes-webp-1536x1518.webp"
                  style={{ objectFit: "cover" }}
                  alt=""
                />
              </div>
            </div>
            <div
              className="col-lg-6 about-text wow fadeInUp"
              data-wow-delay="0.3s"
            >
              <h6 className="text-secondary text-uppercase mb-3">About Us</h6>
              <h1 className="mb-5">Who We Are?</h1>
              <p className="mb-5">
                SN Marine Enterprise was established in 2012, and over the past
                12 years, we have grown from a small business into a leading
                company with subsidiaries nationwide.
              </p>

              <p className="mb-5">
                We are proud to introduce ourselves as a premier ship chandling
                company, operating at Chittagong Port, Mongla Port, and Payra
                Seaport in Bangladesh. Since our founding, SN Marine Enterprise
                has been driven by a dedicated procurement team committed to
                achieving excellence. We supply a comprehensive range of
                shipping goods from Chittagong, Mongla, and Payra Seaports to
                ports across the globe, maintaining a stellar reputation.
              </p>

              <p className="mb-5">
                Our services are also extended to surrounding ports, with an
                efficient and effective system in place. We guarantee quality
                supplies and services, delivered promptly under sustainable
                management practices and competitive pricing.
              </p>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default About;
