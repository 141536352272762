// // src/Navbar.js
// import React from "react";
// import "./Navbar.css"; // Optional for styling

// const Navbar = () => {
//   return (
//     <nav
//       className="navbar"
//       style={{
//         zIndex: "1",
//         position: "relative",
//         borderBottom: " 2px solid rgba(255, 255, 255, 0.08)",
//       }}
//     >
//       <div className="logo">
//         <a href="/">
//           <img src="/images/log.png" alt="Logo" />
//         </a>
//       </div>
//       <ul className="nav-menu">
//         <li>
//           <a href="/">Home</a>
//         </li>
//         <li>
//           <a href="/about">About</a>
//         </li>
//         <li>
//           <a href="/services">Ship Services</a>
//         </li>
//         <li>
//           <a href="/contactus">Contact</a>
//         </li>
//       </ul>
//     </nav>
//   );
// };

// export default Navbar;

// src/Navbar.js
import React, { useState } from "react";
import "./Navbar.css"; // Optional for styling

const Navbar = () => {
  const [isOpen, setIsOpen] = useState(false);

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  return (
    <nav
      className="navbar"
      style={{
        zIndex: "1",
        position: "relative",
        borderBottom: "2px solid rgba(255, 255, 255, 0.08)",
      }}
    >
      <div className="logo">
        <a href="/">
          <img src="/images/log.png" alt="Logo" />
        </a>
      </div>
      <div className="hamburger" onClick={toggleMenu}>
        ☰
      </div>
      <ul className={`nav-menu ${isOpen ? "open" : ""}`}>
        <li>
          <a href="/">Home</a>
        </li>
        <li>
          <a href="/about">About</a>
        </li>
        <li>
          <a href="/services">Ship Services</a>
        </li>
        <li>
          <a href="/contactus">Contact</a>
        </li>
      </ul>
    </nav>
  );
};

export default Navbar;
