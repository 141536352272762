import "./App.css";
import { BrowserRouter as Router, Route, Routes, Link } from "react-router-dom";
import Main from "./Components/main";
import Services from "./Components/services/services";
import About from "./Components/Aboutus/about";
import Contactus from "./Components/Contactus/contactus";
function App() {
  return (
    <div className="App">
      <Router>
        <div>
          <Routes>
            <Route path="/" element={<Main />} />
            <Route path="/services" element={<Services />} />
            <Route path="/about" element={<About />} />
            <Route path="/contactus" element={<Contactus />} />
          </Routes>
        </div>
      </Router>
    </div>
  );
}

export default App;
