import React, { useEffect } from "react";
import "./services.css";
import Navbar from "../Navbar/Navbar";
import Footer from "../Footer/Footer";
const Services = () => {
  useEffect(() => {
    document.title = "SERVICES || SN MARINE ENTERPRISE"; // Set default title
  }, []);
  return (
    <>
      <Navbar />
      <div
        className="container-fluid page-header py-5"
        style={{ marginBottom: "6rem", marginTop: -90 }}
      >
        <div className="container py-5">
          <h1 className="display-3 text-white mb-3 animated slideInDown">
            Ship Services
          </h1>
          <nav aria-label="breadcrumb animated slideInDown">
            <ol className="breadcrumb">
              <li className="breadcrumb-item">
                <a className="text-white" href="#">
                  Home
                </a>
              </li>
              {/* <li className="breadcrumb-item">
                <a className="text-white" href="#">
                  Pages
                </a>
              </li> */}
              <li
                className="breadcrumb-item text-white active"
                aria-current="page"
              >
                Services
              </li>
            </ol>
          </nav>
        </div>
      </div>

      <div className="container-xxl py-5">
        <div className="container py-5">
          <div className="text-center wow fadeInUp" data-wow-delay="0.1s">
            <h6 className="text-secondary text-uppercase">Our Services</h6>
            <h1 className="mb-5">Explore Our Services</h1>
          </div>
          <div className="row g-4">
            <div
              className="col-md-6 col-lg-4 wow fadeInUp"
              data-wow-delay="0.3s"
            >
              <div className="service-item p-4">
                <div className="overflow-hidden mb-4">
                  <i class="fa-solid fa-ship"></i>
                </div>
                <h4 className="mb-3">Air Freight</h4>
              </div>
            </div>
            <div
              className="col-md-6 col-lg-4 wow fadeInUp"
              data-wow-delay="0.5s"
            >
              <div className="service-item p-4">
                <div className="overflow-hidden mb-4">
                  <i class=" fas fa-tools"></i>
                </div>
                <h4 className="mb-3">Ocean Freight</h4>
              </div>
            </div>
            <div
              className="col-md-6 col-lg-4 wow fadeInUp"
              data-wow-delay="0.7s"
            >
              <div className="service-item p-4">
                <div className="overflow-hidden mb-4">
                  <i class=" fas fa-weight"></i>
                </div>
                <h4 className="mb-3">Road Freight</h4>
              </div>
            </div>
            <div
              className="col-md-6 col-lg-4 wow fadeInUp"
              data-wow-delay="0.3s"
            >
              <div className="service-item p-4">
                <div className="overflow-hidden mb-4">
                  <i class="fas fa-certificate"></i>
                </div>
                <h4 className="mb-3">Train Freight</h4>
              </div>
            </div>
            <div
              className="col-md-6 col-lg-4 wow fadeInUp"
              data-wow-delay="0.5s"
            >
              <div className="service-item p-4">
                <div className="overflow-hidden mb-4">
                  <i class=" fas fa-toolbox"></i>
                </div>
                <h4 className="mb-3">Customs Clearance</h4>
              </div>
            </div>
            <div
              className="col-md-6 col-lg-4 wow fadeInUp"
              data-wow-delay="0.7s"
            >
              <div className="service-item p-4">
                <div className="overflow-hidden mb-4">
                  <i class=" fas fa-tools"></i>
                </div>
                <h4 className="mb-3">Warehouse Solutions</h4>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* ----------------services --------------- */}

      <div className="container-xxl py-5">
        <div className="container py-5">
          <div className="row g-5 align-items-center">
            <div className="col-lg-5 wow fadeInUp" data-wow-delay="0.1s">
              {/* <h6 className="text-secondary text-uppercase mb-3">
                Get A Quote
              </h6> */}
              <h1 className="mb-5">Husbandry Services</h1>
              <ul>
                <li style={{ textAlign: "justify" }}>
                  Spares clearance and delivery
                </li>
                <li style={{ textAlign: "justify" }}>
                  Inward & outward clearance
                </li>
                <li style={{ textAlign: "justify" }}>
                  Liaison with local authorities
                </li>
                <li style={{ textAlign: "justify" }}>
                  Follow-up activities with workshops, contractors etc.
                </li>
                <li style={{ textAlign: "justify" }}>
                  Bunker fuels and lubricants
                </li>
                <li style={{ textAlign: "justify" }}>
                  Communication assistance
                </li>
                <li style={{ textAlign: "justify" }}>
                  Emergency medical evacuation
                </li>
                <li style={{ textAlign: "justify" }}>
                  Crew medical assistance
                </li>
              </ul>
            </div>
            <div className="col-lg-7">
              <div
                className="bg-light text-center p-5 wow fadeIn"
                data-wow-delay="0.5s"
              >
                <img
                  style={{ height: 400, width: 400 }}
                  src={
                    "https://allseasshipping.com/wp-content/uploads/2023/03/blog-20.jpeg"
                  }
                />
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="container-xxl py-5">
        <div className="container py-5">
          <div className="row g-5 align-items-center">
            <div className="col-lg-7">
              <div
                className="bg-light text-center p-5 wow fadeIn"
                data-wow-delay="0.5s"
              >
                <img
                  style={{ height: 400, width: 400 }}
                  src={
                    "https://media.istockphoto.com/id/157913803/photo/metalworker.jpg?s=612x612&w=0&k=20&c=a_WhwF9RNY_KxSbKP9lJV7-ZXAwxdcLFfs1JJn1NUJw="
                  }
                />
              </div>
            </div>
            <div className="col-lg-5 wow fadeInUp" data-wow-delay="0.1s">
              {/* <h6 className="text-secondary text-uppercase mb-3">
                Get A Quote
              </h6> */}
              <h1 className="mb-5">Ship Repairs</h1>
              <ul>
                <li style={{ textAlign: "justify" }}>Steel Repair</li>
                <li style={{ textAlign: "justify" }}>
                  Ship crane & windlass winch repair & maintenance
                </li>
                <li style={{ textAlign: "justify" }}>
                  Provision Crane/Engine Room Crane
                </li>
                <li style={{ textAlign: "justify" }}>
                  All kind of Grab Repair
                </li>
                <li style={{ textAlign: "justify" }}>
                  Anchor & Chain replacement/maintenance
                </li>
                <li style={{ textAlign: "justify" }}>
                  Electric motor overhauling/rewinding.
                </li>
                <li style={{ textAlign: "justify" }}>
                  Electrical Repairs and Certification
                </li>
                <li style={{ textAlign: "justify" }}>
                  Turbo Charger repair/maintenance.
                </li>

                <li style={{ textAlign: "justify" }}>
                  Gangway ladder repair/fabrications
                </li>
                <li style={{ textAlign: "justify" }}>
                  Repair turbo chargers for heat exchange, boilers pumps and
                  ship mechanisms.
                </li>
                <li style={{ textAlign: "justify" }}>
                  Marine Hydraulic motor/pump reconditioning & maintenance
                </li>
                <li style={{ textAlign: "justify" }}>
                  Marine Spare parts reconditioning
                </li>
                <li style={{ textAlign: "justify" }}>
                  under water repairs, inspection and cleaning.
                </li>
                <li style={{ textAlign: "justify" }}>
                  Overhaul of main and auxiliary engine and diesel generators.
                </li>
                <li style={{ textAlign: "justify" }}>
                  Repair of steering gear and variable - stroke pumps.
                </li>
                <li style={{ textAlign: "justify" }}>
                  Repair of refrigeration and air conditioning system.
                </li>
                <li style={{ textAlign: "justify" }}>
                  Repair of hydraulic systems and controlling systems of
                  hydraulics (manipulator).
                </li>
                <li style={{ textAlign: "justify" }}>
                  Repair of Load-Lifting ship devices.
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>

      <div className="container-xxl py-5">
        <div className="container py-5">
          <div className="row g-5 align-items-center">
            <div className="col-lg-5 wow fadeInUp" data-wow-delay="0.1s">
              <h1 className="mb-5">LSA & FFA Service</h1>
              <h6 className="text-secondary text-uppercase mb-3">
                Fire Fighting Equipments
              </h6>
              <ul>
                <li style={{ textAlign: "justify" }}>
                  Fire Extinguisher All Types Portable & Non-Portable
                </li>
                <li style={{ textAlign: "justify" }}>Fixed CO2 System</li>
                <li style={{ textAlign: "justify" }}>FM 200& Novec 1230</li>
                <li style={{ textAlign: "justify" }}>
                  Sprinkler/ Water Mist System
                </li>
                <li style={{ textAlign: "justify" }}>Fire Detection System</li>
              </ul>

              <h6 className="text-secondary text-uppercase mb-3">
                Life Saving Equipments
              </h6>
              <ul>
                <li style={{ textAlign: "justify" }}>
                  Fire Extinguisher All Types Portable & Non-Portable
                </li>
                <li style={{ textAlign: "justify" }}>Fixed CO2 System</li>
                <li style={{ textAlign: "justify" }}>FM 200& Novec 1230</li>
                <li style={{ textAlign: "justify" }}>
                  Sprinkler/ Water Mist System
                </li>
                <li style={{ textAlign: "justify" }}>Fire Detection System</li>
                <li style={{ textAlign: "justify" }}>
                  Inflatable Life Jackets
                </li>
              </ul>
            </div>
            <div className="col-lg-7">
              <div
                className="bg-light text-center p-5 wow fadeIn"
                data-wow-delay="0.5s"
              >
                <img
                  style={{ height: 400, width: 400 }}
                  src={
                    "https://bravoship.com/wp-content/uploads/2021/12/lsa-ffa.jpg"
                  }
                />
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="container-xxl py-5">
        <div className="container py-5">
          <div className="row g-5 align-items-center">
            <div className="col-lg-7">
              <div
                className="bg-light text-center p-5 wow fadeIn"
                data-wow-delay="0.5s"
              >
                <img
                  style={{ height: 400, width: 400 }}
                  src={
                    "https://bravoship.com/wp-content/uploads/2021/11/bravo-navigational-repair.jpg"
                  }
                />
              </div>
            </div>

            <div className="col-lg-5 wow fadeInUp" data-wow-delay="0.1s">
              {/* <h6 className="text-secondary text-uppercase mb-3">
                Get A Quote
              </h6> */}
              <h1 className="mb-5">Navigational Repair</h1>
              <ul>
                <li style={{ textAlign: "justify" }}>Gyro</li>
                <li style={{ textAlign: "justify" }}>Radar</li>
                <li style={{ textAlign: "justify" }}>GPS</li>
                <li style={{ textAlign: "justify" }}>
                  Inmarsat A, B, C (Satcom)
                </li>
                <li style={{ textAlign: "justify" }}>M & MiniM</li>
                <li style={{ textAlign: "justify" }}>
                  Magnetic Compass Deviation Card
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>

      <div className="container-xxl py-5">
        <div className="container py-5">
          <div className="row g-5 align-items-center">
            <div className="col-lg-5 wow fadeInUp" data-wow-delay="0.1s">
              {/* <h6 className="text-secondary text-uppercase mb-3">
                Get A Quote
              </h6> */}
              <h1 className="mb-5">Engineering Works</h1>
              <p className="mt-5">
                We can source the right Engineers, IT Technicians, Mechanics,
                Welders, Fitters as per the request & needs of our good fleet
                Owners & vessel Captains.
              </p>
            </div>

            <div className="col-lg-7">
              <div
                className="bg-light text-center p-5 wow fadeIn"
                data-wow-delay="0.5s"
              >
                <img
                  style={{ height: 400, width: 400 }}
                  src={
                    "https://bravoship.com/wp-content/uploads/2021/12/engineering-works.jpg"
                  }
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default Services;
