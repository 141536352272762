import React, { useEffect, useState } from "react";

import Navbar from "../Navbar/Navbar";
import Footer from "../Footer/Footer";

const Contactus = () => {
  useEffect(() => {
    document.title = "CONTACT US || SN MARINE ENTERPRISE"; // Set default title
  }, []);

  const [formData, setFormData] = useState({
    name: "",
    email: "",
    mobile: "",
    note: "",
  });

  const [errors, setErrors] = useState({});

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const validateForm = () => {
    const newErrors = {};
    if (!formData.name) newErrors.name = "Name is required.";
    if (!formData.email) {
      newErrors.email = "Email is required.";
    } else if (!/\S+@\S+\.\S+/.test(formData.email)) {
      newErrors.email = "Email is invalid.";
    }
    if (!formData.mobile) newErrors.mobile = "Mobile number is required.";
    if (!formData.note) newErrors.note = "Note is required.";
    return newErrors;
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const validationErrors = validateForm();
    if (Object.keys(validationErrors).length > 0) {
      setErrors(validationErrors);
      return;
    }

    // emailjs
    //   .send(
    //     "service_1dw1x59",
    //     "__ejs-test-mail-service__",
    //     formData,
    //     "_8c9naIHGdyu_HUtIn0LD"
    //   )
    //   .then((response) => {
    //     console.log("SUCCESS!", response.status, response.text);
    //     alert("Message sent successfully!");
    //     setFormData({ name: "", email: "", mobile: "", note: "" }); // Reset form
    //   })
    //   .catch((err) => {
    //     console.error("FAILED...", err);
    //     alert("Failed to send the message. Please try again later.");
    //   });
  };

  return (
    <>
      <Navbar />
      <div
        className="container-fluid page-header py-5"
        style={{ marginBottom: "6rem", marginTop: -90 }}
      >
        <div className="container py-5">
          <h1 className="display-3 text-white mb-3 animated slideInDown">
            Contact Us
          </h1>
          <nav aria-label="breadcrumb animated slideInDown">
            <ol className="breadcrumb">
              <li className="breadcrumb-item">
                <a className="text-white" href="#">
                  Home
                </a>
              </li>
              <li
                className="breadcrumb-item text-white active"
                aria-current="page"
              >
                Contact Us
              </li>
            </ol>
          </nav>
        </div>
      </div>
      <div className="container-xxl py-5">
        <div className="container py-5">
          <div className="row g-5 align-items-center">
            <div className="col-lg-5 wow fadeInUp" data-wow-delay="0.1s">
              <h6 className="text-secondary text-uppercase mb-3">
                Get A Quote
              </h6>
              <h1 className="mb-5">Request A For Quote!</h1>
              <p className="mb-5">
                Feel free to email. We had love to hear from you & we will
                quickly be in touch.
              </p>
              <div className="d-flex align-items-center">
                <i className="fa fa-headphones fa-2x flex-shrink-0 bg-primary p-3 text-white" />
                <div className="ps-4">
                  <h6>Call for any query!</h6>
                  <h3 className="text-primary m-0">+88 01711 705304</h3>
                </div>
              </div>
            </div>
            <div className="col-lg-7">
              <div
                className="bg-light text-center p-5 wow fadeIn"
                data-wow-delay="0.5s"
              >
                <form onSubmit={handleSubmit}>
                  <div className="row g-3">
                    <div className="col-12 col-sm-6">
                      <input
                        type="text"
                        name="name"
                        value={formData.name}
                        onChange={handleChange}
                        className="form-control border-0"
                        placeholder="Your Name"
                        style={{ height: 55 }}
                      />
                      {errors.name && (
                        <span className="text-danger">{errors.name}</span>
                      )}
                    </div>
                    <div className="col-12 col-sm-6">
                      <input
                        type="email"
                        name="email"
                        value={formData.email}
                        onChange={handleChange}
                        className="form-control border-0"
                        placeholder="Your Email"
                        style={{ height: 55 }}
                      />
                      {errors.email && (
                        <span className="text-danger">{errors.email}</span>
                      )}
                    </div>
                    <div className="col-12 col-sm-12">
                      <input
                        type="text"
                        name="mobile"
                        value={formData.mobile}
                        onChange={handleChange}
                        className="form-control border-0"
                        placeholder="Your Mobile"
                        style={{ height: 55 }}
                      />
                      {errors.mobile && (
                        <span className="text-danger">{errors.mobile}</span>
                      )}
                    </div>
                    <div className="col-12">
                      <textarea
                        name="note"
                        value={formData.note}
                        onChange={handleChange}
                        className="form-control border-0"
                        placeholder="Special Note"
                      />
                      {errors.note && (
                        <span className="text-danger">{errors.note}</span>
                      )}
                    </div>
                    <div className="col-12">
                      <button
                        className="btn btn-primary w-100 py-3"
                        type="submit"
                      >
                        Submit
                      </button>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default Contactus;
