import React, { useEffect } from "react";
import Navbar from "../Components/Navbar/Navbar";
import Slider from "../Components/Slider/Slider";
import MarineSpareParts from "../Components/MarineSpareParts/MarineSpareParts";
import MarineOperations from "../Components/MarineOperations/MarineOperations";
import MarineProducts from "../Components/MarineProducts/MarineProducts";
import MarineParts from "../Components/MarineParts/MarineParts";
import Footer from "../Components/Footer/Footer";
const Main = () => {
  useEffect(() => {
    document.title = "SN MARINE ENTERPRISE"; // Set default title
  }, []);
  return (
    <>
      <Slider />
      <MarineSpareParts />
      {/* <MarineOperations />
      <MarineProducts /> */}
      <MarineParts />
      <Footer />
    </>
  );
};

export default Main;
