import React from "react";
import "./MarineParts.css";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";

// Custom Next Button
const NextArrow = (props) => {
  const { onClick } = props;
  return (
    <div className="arrow next" onClick={onClick}>
      &#9654;
    </div>
  );
};

// Custom Previous Button
const PrevArrow = (props) => {
  const { onClick } = props;
  return (
    <div className="arrow prev" onClick={onClick}>
      &#9664;
    </div>
  );
};

const MarineParts = () => {
  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 1,
    nextArrow: <NextArrow />,
    prevArrow: <PrevArrow />,
    responsive: [
      { breakpoint: 1024, settings: { slidesToShow: 3 } },
      { breakpoint: 768, settings: { slidesToShow: 2 } },
      { breakpoint: 480, settings: { slidesToShow: 1 } },
    ],
  };

  const products = [
    {
      name: "Auxiliary Engine",
      image:
        "https://www.merchantnavydecoded.com/wp-content/uploads/2023/06/75.jpg",
    },
    {
      name: "Purifier Control Panel",
      image:
        "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTbPi9Xm4wrqDvm1wpYi19YbSG-zHL7LV7Viw&s",
    },
    {
      name: "Navigation Equipment",
      image:
        "https://5.imimg.com/data5/SELLER/Default/2023/6/320552550/SX/GC/NL/36966172/ship-spare-parts-suppliers-in-mumbai-500x500.jpg",
    },
    {
      name: "Inflatable Boat",
      image:
        "https://shservicebd.com/wp-content/uploads/2023/04/inflatable-boat-price-in-bangladesh-247x296.jpg",
    },
    {
      name: "Purifier ",
      image:
        "https://marinesparesbd.com/cdn/shop/collections/alfa-laval-lopx-713-centrifuge-oil-separator--purifier-fully-refurbished_639_580x.jpg?v=1473429779",
    },
    {
      name: "Liner ",
      image: "/images/product/liner.jpeg",
    },
    {
      name: "Air Comperssor ",
      image: "/images/product/air_compressor.jpeg",
    },
    {
      name: "Auxiliary Engine Spare Parts",
      image: "/images/product/auxiliary_engine_spare_parts.jpeg",
    },
    {
      name: "Dennison ",
      image: "/images/product/dennison.jpeg",
    },
    {
      name: "Main Engine",
      image: "/images/product/main_engine.jpeg",
    },
    {
      name: "Navigation Item ",
      image: "/images/product/navigation_item.jpeg",
    },
    {
      name: "Air Comperssor ",
      image: "/images/product/air_compressor.jpeg",
    },
    {
      name: "Pender ",
      image: "/images/product/pender.jpeg",
    },
  ];

  return (
    <div className="marine-parts-container">
      <h2 className="marine-parts-title">Our Popular Products</h2>
      <Slider {...settings}>
        {products.map((product, index) => (
          <div key={index} className="card">
            <img
              src={product.image}
              alt={product.name}
              className="card-image"
            />
            <h3 className="marine-parts-p">{product.name}</h3>
          </div>
        ))}
      </Slider>
    </div>
  );
};

export default MarineParts;
