import React from "react";
import "./../MarineSpareParts/MarineSpareParts.css";

const MarineSpareParts = () => {
  return (
    // <div className="container">
    //   <div className="content">
    //     <div className="image-container">
    //       <img
    //         src="./images/1.webp"
    //         alt="Marine Spare Parts"
    //         className="spare-image"
    //       />
    //     </div>

    //     <div className="text-container">
    //       <h1 className="title">Secure Delivery</h1>
    //       <h2 className="subTitle">Reliable Marine Spare Parts</h2>
    //       <p className="description">
    //         At our marine spare parts shop, we understand the importance of
    //         getting your parts to you quickly and safely. That's why we offer
    //         convenient shipping options and a reliable delivery service,
    //         ensuring your order arrives on time and in pristine condition.
    //       </p>

    //       <button className="button" onClick={() => alert("Shop Now Clicked!")}>
    //         Shop Now
    //       </button>
    //       <h3 className="subTitle">Explore Our Selection</h3>
    //     </div>
    //   </div>
    // </div>

    <div className="container-xxl py-5">
      <div className="container py-5">
        <div className="row g-5">
          <div className="col-lg-6 wow fadeInUp" data-wow-delay="0.1s">
            <h6 className="text-secondary text-uppercase mb-3">We Provide</h6>
            <h1 className="mb-5">Global Ship Supply</h1>
            <p className="mb-5">
              Grown locally we are offering our complete supply & services
              through our good office globally. We respect & understand your
              needs. We established us with an expert purchasing & procurement
              team to provide our best services to our honorable ship manager,
              ship owner, charter, broker, trader.
            </p>
            <div className="d-flex align-items-center">
              <i className="fa fa-headphones fa-2x flex-shrink-0 bg-primary p-3 text-white" />
              <div className="ps-4">
                <h6>Call for any query!</h6>
                <h3 className="text-primary m-0"> +880 1711-705304</h3>
              </div>
            </div>
          </div>
          <div className="col-lg-6">
            <div className="row g-4 align-items-center">
              <div className="col-sm-6">
                <div
                  className="bg-primary p-4 mb-4 wow fadeIn"
                  data-wow-delay="0.3s"
                >
                  <i className="fa fa-users fa-2x text-white mb-3" />
                  <h2 className="text-white mb-2" data-toggle="counter-up">
                    1234
                  </h2>
                  <p className="text-white mb-0">Chittagong Ports</p>
                </div>
                <div
                  className="bg-secondary p-4 wow fadeIn"
                  data-wow-delay="0.5s"
                >
                  <i className="fa fa-ship fa-2x text-white mb-3" />
                  <h2 className="text-white mb-2" data-toggle="counter-up">
                    1234
                  </h2>
                  <p className="text-white mb-0">Mongla Ports</p>
                </div>
              </div>
              <div className="col-sm-6">
                <div
                  className="bg-success p-4 wow fadeIn"
                  data-wow-delay="0.7s"
                >
                  <i className="fa fa-star fa-2x text-white mb-3" />
                  <h2 className="text-white mb-2" data-toggle="counter-up">
                    1234
                  </h2>
                  <p className="text-white mb-0">Matarbari Ports</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MarineSpareParts;
